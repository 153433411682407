@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MyriadProBold";
  src: local("MyriadProBold"),
    url("./fonts/myriad-pro/myriad-pro-bold.otf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "MyriadProRegular";
  src: local("MyriadProRegular"),
    url("./fonts/myriad-pro/myriad-pro-regular.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "MyriadProLight";
  src: local("MyriadProLight"),
    url("./fonts/myriad-pro/myriad-pro-light.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "MyriadProSemiBold";
  src: local("MyriadProSemiBold"),
    url("./fonts/myriad-pro/myriad-pro-semibold.otf") format("truetype");
  font-weight: 600;
}
