.rubik-h1 {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.noto-sans-tc-h1 {
  font-family: "Noto Sans TC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.noto-sans-elbasan-regular {
  font-family: "Noto Sans Elbasan", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.noto-sans-elbasan-semi-bold {
  font-family: "Noto Sans Elbasan", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.noto-sans-elbasan-regular {
  font-family: "Noto Sans Elbasan", sans-serif;
  font-weight: 00;
  font-style: normal;
}

.ubuntu-sans-h1 {
  font-family: "Ubuntu Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  line-height: 1.2;
}

.ubuntu-sans-h2 {
  font-family: "Ubuntu Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.myriad-pro-bold {
  font-family: "MyriadProBold", sans-serif;
}
.myriad-pro-regular {
  font-family: "MyriadProRegular", sans-serif;
}
.myriad-pro-semibold {
  font-family: "MyriadProSemiBold", sans-serif;
}
.myriad-pro-light {
  font-family: "MyriadProLight", sans-serif;
}
